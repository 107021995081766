/* ------------------------------------------------------------------------- */


/* VARIABLES */


/* ------------------------------------------------------------------------- */

$main: 'Poppins', sans-serif;  /* 400 y 600*/
$second: 'Lora', serif; /* 400 */

$color: #2e654b;
$color_yellow: #DFA500;
$color_blue: #003F5D;
$color_orange: #FF6446;
$color-txt: #000000;
$color-bt: #2E654B;
$color-bg: #f0f0f0;
$color-dark_grey: #969696;
$color-input: white;

$border-radius: 5px;
$line-height: 140%;
$line-height2: 120%;
$line-height3: 160%;
$letter-spacing: 0px;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 600;
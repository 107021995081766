/* ------------------------------------------------------------------------- */


/* GENEREAL STYLE - Classes used throught all the project */


/* ------------------------------------------------------------------------- */


h1, h2, h3, h4, h5, h6 {
	font-family:$main;
	font-weight:$font-weight-bold;
	color:$color-txt;
	line-height:$line-height2;
	margin-bottom:30px;
}

h1 { font-size:24px; }
h2 { font-size:18px; }
h3 { font-size:18px; }
h4 { font-size:16px; }
h5 { font-family:$second; font-weight:$font-weight-normal; font-size:16px; }
h6 { font-size:14px; }

.title_center {text-align: center;}
.title_white {color: white;}


@media only screen and (max-width: 800px){

}

@media only screen and (max-width: 600px){
	h1 { font-size:20px; }
	h2 { font-size:16px; }
	h3 { font-size:16px; }
	h4 { font-size:15px; }
	h5 { font-size:15px; }
	h6 { font-size:13px; }
	h1, h2, h3, h4, h5, h6 { margin-bottom:25px;}
}

@media only screen and (max-width: 480px){
	h1, h2, h3, h4, h5, h6 { margin-bottom:20px;}
}

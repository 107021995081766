/* ------------------------------------------------------------------------- */


/* PAGE PAGE STYLES */


/* ------------------------------------------------------------------------- */

main{ padding-top: 90px;}
h2{ margin-bottom: 30px!important; }
a:hover{ text-decoration: none!important; font-weight: $font-weight-bold!important; color: $color!important;}
.message-hidden{display: none;}
.main_content{ position: relative;left: 0;min-height: 870px !important;padding: 20px 40px 40px 30px;background: transparent;margin: 0px 20px 0px 230px;}
#error_msg{ margin: 0}
.bg-gray-100{ background-color: $color-bg!important;};
.section{padding: 60px 0; border-top: 1px solid white;}
.section_wob{border-top: none; padding: 20px 0px 60px 0;}
.section_title{ color: $color-txt !important; font-family: $second;margin: 0!important; font-weight: $font-weight-normal}
.section_title hr{ margin: 15px 0 20px 0;}
.section_title_span{ text-transform: capitalize}
.form-control textarea { height: auto!important;}
.form_control_section p{ padding: 15px;
    border-radius: 5px;
    height: 52px;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    background: white;
    outline: 0;
    width: 100%;
    border: 1px solid #969696;
}
.status{ font-weight: $font-weight-bold; color:$color; font-size: 14px; margin-bottom: 30px; margin-top: -20px;}
.empty{color: #d90a19 ;}

/*LOGIN*/
.header_logo{ width: 150px }


/* POSTS */
.post-section{display: flex;}
.post-main_section{flex-basis: 70%; padding: 0px 30px 0px 0px;}
.post-side_section{flex-basis: 30%;}
.post-event_content{ max-height: 250px; overflow-x: scroll; background: $color-input;padding: 10px;border-radius: 5px;}
.button_small{ text-align: center; color: white; width: auto!important; padding: 8px 20px!important; margin-left: 15px!important; font-weight: $font-weight-normal; text-transform: uppercase}
.button_small:hover{ color: white; text-decoration: none;}
.button_big{ text-align: center; color: white; width: auto!important; padding: 8px 15px!important; margin-left: 10px!important; font-weight: $font-weight-normal}
.button_big:hover{ color: white; text-decoration: none;}
.btn-block{ border-radius: 0!important;}
.table { table-layout:fixed; }
.table td { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
img.post_thumbnail{ margin: auto; height: 200px; object-fit: cover; }
.open_media{ max-width: 100%; }
.button_file{ padding: 100px 200px!important;background-color: $color-input;color: #8F8F8F;margin-top: 2%; }
.button_lang{cursor: pointer;border: none;color: #8a8a8a !important;width: auto !important;padding: 5px 10px !important;margin-left: 18px !important;
  background: #f5f5f5 !important;display: inline-block;font-size: 14px;font-weight: 400;border-radius: 5px;transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);}
.lang_selected {color: #000000 !important;border: 1px solid #d4d3d3 !important;cursor: auto;}
.thumbnail_content{ background-color: $color-input; border: 1px solid $color-dark_grey;border-radius: 5px;position: relative;}
.thumbnail_button{position: absolute;top: 50%;transform: translateY(-50%);width: 100%;text-align: center;cursor: pointer;}
.thumbnail_button span{    color: white;background-color: #ffffff54;padding: 15px 20px;border-radius: 6px;}
.note-codable { min-height: 600px !important; }

/* EVENTS */
.event-section{display: flex;}
.event-main_section{flex-basis: 70%; padding: 0px 30px 0px 0px;}
.event-side_section{flex-basis: 30%;}


/* MEMBERS*/
.member-section{display: flex;}
.member-main_section{flex-basis: 70%; padding: 0px 30px 0px 0px;}
.member-side_section{flex-basis: 30%;}
.member-text_content{}


/*DATATABLE*/
.dataTable{ font-size: 14px}
.dataTables_length label{ display: flex!important; margin-top: 10px!important;margin-bottom: 20px!important;}
.dataTables_wrapper .dataTables_filter input { width: 90%;padding: 15px!important;border-radius: 5px!important;height: 35px!important;color: #000000!important;font-size: 14px!important;background: $color-input!important;outline: 0!important;border: none !important;}
.dataTables_length label select{width: 80px!important;margin: -4px 10px 0px 10px!important;}
.dataTables_wrapper .dataTables_filter input{background-color: #fff;border-color: #6b7280;border-width: 1px;border-radius: 0px;padding-top: 0.5rem;padding-right: 0.75rem;padding-bottom: 0.5rem;padding-left: 0.75rem;}
table.dataTable.no-footer{ border-bottom: none!important;}
.dataTables_wrapper .dataTables_length select{ padding: 0px 15px!important;border-radius: 5px!important;height: 35px!important;color: #000000!important;font-size: 14px!important;border: none !important;}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {cursor: default;color: $color-txt !important;border: 1px solid transparent;background: white;box-shadow: none;border-radius: 5px !important;margin: 0px 10px;}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover{color: white!important;}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button:hover{ color: white!important; background: $color !important; border:none!important; border-radius: 5px!important;}
.row{margin-top: 30px}
table.dataTable tbody th, table.dataTable tbody td { padding: 15px 10px!important;}
.dataTables_wrapper {margin-bottom: 30px!important;}
.dataTables_paginate{ margin-top: 20px!important;}
.table_logo{width: 50px;height: 50px;object-fit: cover;outline: 6px solid #f5f5f5;}
.dataTables_wrapper .dataTables_filter{ width: 60%; float: left; margin-top: 7px; text-align: left;}
#sent-table_filter{ display: none}
.dataTables_filter label{ width: 100%}
.dataTables_wrapper .dataTables_length { float: right};
.table thead{ background-color: #ccdbd6};
table.dataTable thead .sorting {background-image: url(../images/sort_both.png)!important;}
table.dataTable thead .sorting_desc {background-image: url(../images/sort_desc.png)!important;}
table.dataTable thead .sorting_asc {background-image: url(../images/sort_asc.png)!important;}
thead th:first-child {border-left: none;border-top-left-radius: 5px;}
thead th:last-child {border-left: none;border-top-right-radius: 5px;}
td a:hover{ color: $color}
.delete_btn{ color: #d90a19!important;}
.dataTables_wrapper.no-footer .dataTables_scrollBody {border-bottom: none;}
table.dataTable.display tbody tr > .sorting_1, table.dataTable.order-column.stripe tbody tr > .sorting_1 {background-color: #ffffff!important;}
table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {background-color: #D7D7D7!important;}
table.dataTable tbody tr {background-color: #ffffff!important;}
.table-hover tbody tr:hover {background-color: #D7D7D7!important;}
.table_link{ color: #006740}


/*MODAL*/
.modal-footer{justify-content: center; border-top: none; padding-bottom: 30px;}
.modal-footer a{ width: 100%}
.modal-title{ font-family: $main; font-weight: $font-weight-bold; font-size: 17px; width: 100%}
.modal-content{ border: none; border-radius: 10px;}
.note-modal .modal-dialog{ box-shadow: none;}
.modal-newsletter{ padding: 0px 30px;margin-top: -25px;}
.modal .note-modal .in{background-color: rgba(0, 0, 0, 0.4);}
button.close{display: none}
.modal-preheader{ padding: 10px 20px 10px 30px;  justify-content: left; border: none; text-align: center}
.modal-header{ justify-content: left; padding: 10px 30px 30px 30px; border: none; text-align: center}
.modal-dialog {max-width: 700px}
div.form-group.note-group-image-url{ margin-top: 30px;}
div.form-group.note-form-group.note-group-select-from-files{ margin-top: 10px}
.btn-primary{font-family: $main; color: white!important; display: inline-block; background: $color; font-size: 14px; font-weight: $font-weight-bold; cursor: pointer;
    border-radius: 5px; border: 1px solid transparent; padding: 15px 25px; width: 170px; -webkit-appearance: none; transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95); }
.btn-primary:hover { background: #00C97D; color: white!important; border-color: #00C97D; font-weight: $font-weight-bold;}
.btn-primary:disabled { background: #068052; color: white!important; border-color: #068052; font-weight: $font-weight-bold;}
.btn-primary[disabled]:hover { background: #068052; color: white!important; border-color: #068052; font-weight: $font-weight-bold;}
.modal_buttons{display: flex; margin-top: 40px;}
.modal_buttons a{flex-basis: 50%; padding: 60px 20px;border: 1px solid $color;border-radius: 10px;margin: 0px 50px;}
.modal_buttons a:hover{background-color: $color; color: white!important;}
.modal-download p{ text-align: left}

/*MEDIA POPUP*/
.media-section-background{display: none;left: 0;top: 0;width: 100%;height: 100%;position: fixed;z-index: 1000;background-color: rgba(0, 0, 0, 0.7);}
.media-section-container {position: relative;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;}
.media-close-section {position: absolute;width: 100%;height: 100%;left: 0;top: 0;z-index: 1;}
.media-info-container {background-color: #fff;margin: auto;width: 90%;max-height: 100%;max-width: 1000px;z-index: 1;overflow: auto;}
.media-header {padding: 30px 0;}
.media-header p {text-align: left;margin: auto 0;margin-left: 30px;font-weight: $font-weight-bold;line-height: 120%;font-size: 24px;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {color: $color!important;}
.tab-regular .tab-content {background-color: #fff;padding: 30px;border: 1px solid #e6e6f2;border-radius: 4px;border-top-left-radius: 0px;}
.modal-scroll {overflow-y: scroll;height: 50vh;text-align: left!important;}
.media-img {height: 150px;margin-top: 5px;object-fit: cover;display: inline;width: 150px;}
.img-active {border: 4px solid $color;}
.dropzone{ border: none!important;}
.modal-body{min-height: 300px!important;}
#uploadMedia .modal-body{ text-align: center;}


/*ORGANIZATION CHART*/
.organization_section{ display: flex}
.organization-row { flex-basis: 50%; padding: 10px;}
.list-row .list-item { flex-direction: row;-ms-flex-align: center;align-items: center;padding: .75rem .625rem;border-radius: 5px;}
.list-item {position: relative;display: flex;flex-direction: column;min-width: 0;word-wrap: break-word}
.list-row .list-item>* {padding-left: .625rem;padding-right: .625rem}
.flex-div { flex: 1 1 auto}
.list-item {position: relative;display: -ms-flexbox;display: flex; flex-direction: column;min-width: 0;word-wrap: break-word}
.list-item.block .media {border-bottom-left-radius: 0;border-bottom-right-radius: 0}
.list-item.block .list-content {padding: 1rem}
.list-item-bold{ font-weight: $font-weight-semibold;font-size: 15px;}
.list-item-small{ font-weight: $font-weight-normal; font-size: 13px;}
.input-section{ display: flex; margin-left: 5px; flex-basis: 90%; }
.button-section{flex-basis: 8%; margin-top: 15px;}
.input-section .three-column{ margin: 0px 5px}
.item-delete{ float: right; color: #d01818!important; font-size: 11px;text-transform: uppercase;}
.item-delete img{ width: 20px}
.item-author{cursor: pointer; font-weight: $font-weight-normal; color: $color-txt!important;}


/*CONTACTS*/
.contact-section{ display: flex;}
.contact-main_section { flex-basis: 50%; padding: 0px 30px 0px 0px;}
.contact-label{font-weight: $font-weight-bold;margin-bottom: 10px;font-size: 16px;}
p.form-control{ height: auto!important;}
a.form-control{ height: auto!important;}


/*IMPORTS*/
.dz-message {text-align: center;padding: 1.5rem;display: block;font-size: .875rem;font-weight: 500;opacity: 1;cursor: pointer;border-radius: 0.5rem;background: #fff;
    border: 1px dashed #E9EEF2;transition: all .35s ease;}
.dropzone {min-height: 150px;border: 1px solid $color!important;background: white;padding: 20px 20px;}
.dropzone-title{ margin-bottom: 20px; }
.excel-loader{width: 30px;margin: auto;margin-top: 30px;}
.dropzone-no-borders{ border: none!important;}
.hundreds__loader svg{ margin: auto}

/* SEO*/
.seo_section{ display: flex}
.seo_column { flex-basis: 50%; padding: 5px 10px;}


/*POPUP*/
.popup-section{display: flex;}
.popup-main_section{flex-basis: 30%;padding: 0px 30px 0px 0px;}
.popup-right_section{ flex-basis: 70%; }


/*NEWSLETTER*/
.newsletter-main_section{flex-basis: 50%; padding: 0px 40px 0px 0px;}
.newsletter-side_section{flex-basis: 50%; padding: 0px}
.newsletter_background{background-color: white; border-radius: 10px}
.newsletter_header{display: flex;justify-content: space-between; padding: 30px; border-bottom: 6px solid $color;}
.newsletter_content{}
.newsletter_title hr{ margin-left: 0;}
.newsletter_type{ text-transform: uppercase; font-weight: $font-weight-bold; font-size: 18px; font-family: $main!important;}
.newsletter_date{ font-size: 14px}
.newsletter_content_section{ padding: 30px; border-bottom: 3px solid $color-bg;}
.newsletter_content_section hr{ margin-left: 0;}
.newsletter_content_section p { font-family: $second}
.nav-dots { display:flex; justify-content: space-between;}
.nav-dots .section-step { position: relative;display:flex; align-items: flex-start; justify-content: flex-start; margin-bottom:15px; cursor:pointer;}
.nav-dots .section-step span { width:35px; height:35px; border-radius:50%; display: inline-flex; align-items: center; justify-content: center; color:$color-txt; font-size:12px; line-height: 100%; z-index: 1; margin-top:-9px; margin-right:12px;}
.nav-dots .section-step p { font-size: 14px; font-weight: $font-weight-bold; color: $color; line-height: $line-height2; margin:0; flex:1; }
.nav-dots .section-step.saved span { background-color:#AAB2FB; color:white;}
.nav-dots .section-step:hover { color:$color; }
.nav-dots .section-step:hover span { color:$color; }
.nav-dots .section-step.section-step_selected span{ background-image: url(../images/tick_selected.png); }
.nav-dots .section-step.section-step_not_selected span{ background-image: url(../images/tick_not_selected.png); }
.newsletter-title{text-transform: uppercase; padding-top: 30px; font-size: 20px; font-weight: $font-weight-semibold}
.newsletters_step{ padding-top: 20px}
.tenders_content { padding: 10px 20px; background: $color-bg; border-radius: 10px; margin-top: 25px;}
.tenders_content p { font-family: $main!important}
.sortable_content{width: 95%}
.sortable_items { padding: 15px 20px 5px 20px; background: $color-bg; border-radius: 10px; margin: 15px 0px; display: flex; justify-content: space-between;}
.sortable_title{ font-weight: $font-weight-bold}
.sortable_action{ width: 5%; text-align: right}
.sortable_label{ text-transform: uppercase; width: fit-content;background: rgba(255,255,255,0.2);padding: 5px 10px;border-radius: 5px; }
.newsletter_items{ padding: 20px 20px 10px 20px;}
.item-ccoc{ background-color: #003F5D; color: white; margin: 15px 0px; border-radius: 5px}
.newsletter_items p{font-family: $main!important;}
.item-public{ background-color: #DFA500; color: white; margin: 15px 0px; border-radius: 5px }
.item-cambra{ background-color: #006740; color: white; margin: 15px 0px; border-radius: 5px }
.item-event{ display: flex; padding-left: 0px; height: 70px; margin-bottom: 10px}
.event_date{ padding: 15px; background-color: #003F5D; color: white; text-transform: uppercase; width: 80px; text-align: center; font-size: 14px; border-radius: 5px 0px 0px 5px; height: 70px;}
.event_date__second { background-color: #006740 }
.event_date__third { background-color: #DFA500 }
.event_title{ padding: 15px; flex-basis: 100%; background: #F0F0F0; color: black; font-weight: $font-weight-bold; height: 70px;}
.event_date span{font-size: 30px;}
.item-file{ background-color: #F0F0F0; color: black; margin: 5px; border-radius: 5px; flex-basis: 47%;}
.item-file_section{ display: flex; flex-wrap: wrap;}
.item-file.sortable_label{ background-color: white;}
.file_label{background: white}
.section_show{ display: block}
.section_hidden{ display: none}
.section_btn{ display: flex; justify-content: space-between}
.section_btn a{ margin-left: 0!important;}
.newsletter_contact_section{ display: flex}
.newsletter_contact_section input:first-child{ margin-right: 10px;}
.newsletter-step3_title{font-size: 20px; text-align: center; color: $color;}
.newsletter-step3_content{ text-align: center}
.newsletter_message{text-align: center}
.table_btn img{ width: 16px}
.search_row{ display: flex; justify-content: space-between; padding: 15px 0px;}
.date_input{ display: flex; justify-content: space-between;}
.search_input{ width: 60%}
.form-control_search{ background-color: #F4F4F4!important; border: none!important;}
.date_input label{ margin: 15px 10px 10px 10px;}
.newsletter-section{display: flex; justify-content: center}
.newsletter_small_section{width: 50%}
.section_bloc{display: flex}
.section_bloc__time{width: 30%; margin-right: 10px;}
.section_bloc__description{width: 60%}
.section_bloc__delete{width: 5%; margin-left: 8px}
.bloc_title{display: flex; margin-bottom: 10px;}
.bloc_title p{font-weight: $font-weight-bold; font-size: 16px}
.single-news-header {display: flex;background: white;overflow: hidden;}
.single-news-header .single-news-header__left {flex-basis: 50%;padding-top: 20px;padding-bottom: 30px;}
.single-news-header .single-news-header__right {flex-basis: 50%;background-size: cover;background-position: center;}
.single-news-header__right img{height: 100%;object-fit: cover;}
.tags span {color: white; display: inline-block;text-transform: uppercase;font-size: 12px;line-height: 120%;border-radius: 5px;background-color: rgba(255, 255, 255, 0.2);padding: 5px 10px;margin: 0 5px 5px 0;}
.newsletter_section_title{ font-size: 16px; margin-top: 10px; color: white; margin-bottom: 20px;}
.news_background_altres{background-color: $color_orange}
.news_background_projectes{background-color: $color_yellow}
.news_background_socis{background-color: $color_blue}
.news_background_la_cambra{background-color: $color}
ul.event-list li {margin-bottom: 10px;display: flex;align-items: flex-start;color: white}
ul.event-list li img {margin-right: 10px;min-width: 25px;}
ul.event-list li span {display: inline-block;margin-top: 3px;}
.newsletter_button{text-align: center; padding-top: 20px; padding-bottom: 20px;}
.newsletter-content p{font-family: $main}
.program_title{ font-family: $main!important; margin-top: 20px; text-transform: uppercase; font-weight: $font-weight-bold}
.divider {color: $color;font-weight: $font-weight-bold;padding: 0 5px;}
.program_timeline{margin-top: 15px;}
.logo_section{display: flex; margin-bottom: 10px;}
.logo_section p {margin-right: 10px;}
.openSeoMedia{ cursor: pointer}
.logos_section img{ width: 100px; margin: 10px; }
.logos_section{display: flex; margin-bottom: 10px; flex-wrap: wrap;}

#multiple_files hr{width: auto;margin: 20px 0px;background: #afb5b878;}

@media only screen and (max-width: 800px) {
  .main_content {margin-left: 30px;}
  .nav-left-sidebar{ display: none}
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
figure,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    text-decoration: none;
}


/* ==========================================================================
   RESET CSS
   ========================================================================== */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
figure,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    text-decoration: none;
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: middle;
}

a {
    text-decoration: none;
    outline: 0;
}

a:hover {
    text-decoration: none;
}

* {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

p a {
    outline: 0;
}

p a:hover {
    text-decoration: underline;
}

input[type='submit'],
input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

html {

}

html,
body {
    overflow-x: hidden;
}
/* ------------------------------------------------------------------------- */


/* GENERAL FORM STYLES */


/* ------------------------------------------------------------------------- */

form label{ width: 100%; font-weight: $font-weight-bold!important; margin-bottom: 10px!important; display: inline-block!important; font-size: 16px!important; line-height: $line-height2!important; }
*:focus { outline: none!important; box-shadow: none!important; border: none}
form input:focus { border: 1px solid $color-dark_grey!important; --tw-ring-color: $color-dark_grey!important;}
form input[ type=checkbox]{border: 1px solid $color; }
select { padding: 17px;  background: $color-input url(../images/arrow-select.svg) calc(100% - 10px) 50% no-repeat!important; font-size: 14px; font-family: $main; font-weight:$font-weight-normal; line-height:$line-height; border-radius:$border-radius; color:$color-txt; width:100%; border:none; box-sizing: border-box; outline: 0; -webkit-appearance: none;}
.submit_button{display: inline-flex;; font-family: $main; color: white!important; background: $color; font-size: 14px; font-weight: $font-weight-normal!important; cursor: pointer;
    border-radius: 5px; border: 1px solid transparent; padding: 10px 25px; width: 170px; -webkit-appearance: none; transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95); }
.submit_button:hover { background: #00C97D; color: white!important; font-weight: $font-weight-normal!important;}
.download_button {margin-left: 7px;transition: all 0.2s;}
.submit_button:hover .download_button {transform: translateY(5px);}
.preview_button{ background: $color_blue}
.preview_button:hover{background: #1a526d}
.grey-button{ background: transparent;color: $color!important;font-weight: $font-weight-normal !important;border: 1px solid $color;}
.grey-button:hover{  background: transparent!important; font-weight: $font-weight-normal !important;color: #00C97D!important; border: 1px solid #00C97D;}
.delete_option{padding: 15px 5px}
.delete_cross{color: red}
.delete-button{background: none; color: red!important;}
.delete-button:hover{background: none; color: rgba(150, 0, 0, 0.85) !important;}
.flex-column{ flex-direction: column;}
.password_text{ margin-bottom: 20px}
.form_label{color: $color-txt; cursor: pointer; font-size: 14px; font-weight: $font-weight-normal; margin-left: 10px; }
[type='checkbox']:checked { background-image: none!important; background-color: $color!important; border: 1px solid $color; }
.big-input{ font-size: 20px!important; }
.form-control:focus {background-color: white!important;border: 1px solid $color-dark_grey!important;}
.search_white {background-color: white !important;color: #6b7280!important;height: 35px!important;padding: 0px 15px!important;}
.form-check-label { margin-top: 2px; margin-right: 15px; }
[type='checkbox'], [type='radio']{ color: $color!important; }
.button_save { text-align: right;}
.two-columns{ display: flex; align-items: flex-start;justify-content: space-between; }
.column{ flex-basis: 49%}
.input-group-text{ border: none!important;}
.two-column { flex-basis: 48%; margin-right: 5px}
.three-column{ flex-basis: 33%}
.four-column{ flex-basis: 25%}
.form-inline{width: 30%!important;display: inline!important;}
.right-button{ float: right}
.large-input{ width: 100%!important;}
.add_button {text-align: center;background: $color;border-radius: 8px;color: white!important;padding: 15px 20px;margin-top: 10px;}
.add_button:hover {background: #00C97D;border-radius: 8px;color: white!important;padding: 15px 20px;margin-top: 10px;}
.form_comment {font-size: 15px;color: $color-dark_grey;font-weight: $font-weight-normal; padding: 0px 0px 15px 0px; margin-top: 10px; width: 100%; margin-bottom: 0;}
.button_large{ width: 100%; text-align: center}
.button_grey { background-color: #79838899;border: none; color: white; font-family: $main; font-size: 14px;}
.form_group{background-color: white;padding: 25px;border-radius: 10px;margin-bottom: 23px;}
.input-group input {border-left: none;}
.input-group-pw input{ border: 1px solid $color-dark_grey;}
.input-group-text{ border: 1px solid $color-dark_grey!important; background-color: $color-bg!important; padding: 10px 20px;}
.label_description { font-size: 13px; font-style: italic }
.input-group-text img{ width: 30px;}
.group-rrss { background-color: transparent!important;padding: 10px!important;}
//.borderless-input:focus{border-left: none!important;}
.input-group-text{ color: $color-dark_grey}
.select2-container { width: 100%!important;}
.select2-container--default .select2-selection--single{ padding: 10px;border-radius: 5px;height: 52px !important;}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {background-color: $color!important;}
.select2-search__field{border: 1px solid #aaa!important;}
.select2-selection__arrow{padding: 17px;  background: $color-input url(../images/arrow-select.svg) calc(100% - 10px) 50% no-repeat!important; top: 10px!important;}
.select2-container--default .select2-selection--single .select2-selection__arrow b{display: none;}
.hundreds-validation__input_error { border: 1px solid red!important;}
.error_message{ color: red;}
.form-group_step{margin-bottom: 30px;}
.form-group_step label{ font-weight: $font-weight-normal!important; margin-bottom: 0px!important;}
.custom-file-label{padding: 15px;border-radius:$border-radius;height: 52px!important;color:$color-txt;font-family: $main;font-weight:$font-weight-normal!important;font-size: 14px;background: $color-input;outline: 0;width: 100%;transition: all .5s cubic-bezier(.15,.9,.34,.95);-webkit-appearance: none;border: 1px solid $color-dark_grey;}
.hundreds-form__checkbox input[type=radio], .hundreds-form__checkbox input[type=checkbox] { display: none;}
.hundreds-form__checkbox {}
.hundreds-form__checkbox_download{ display: flex; flex-wrap: wrap}
.hundreds_checkbox{flex: 50%}
.hundreds-form__checkbox label { font-family: $main; color: $color-txt;  cursor: pointer;  display: inline-block; font-size: 14px; font-weight:$font-weight-normal!important; line-height: $line-height2; vertical-align: middle; padding-left: 30px; margin-top:15px; position: relative; text-align: left;}
.hundreds-form__checkbox label:before { content: ''; display: block; position: absolute; top: 0px; left:0; height: 20px; width: 20px; border: 1px solid $color; background-color:transparent; border-radius:10px; }
.hundreds-form__checkbox label:after { content: ''; display: block; position: absolute; top: 3px; left:3px; height: 14px; width: 14px; background-color:white; border-radius:10px; transition: all .5s cubic-bezier(.15,.9,.34,.95);}
.hundreds-form__checkbox label a { color:$color; border-bottom: 1px solid $color-txt;  }
.hundreds-form__checkbox input[type=radio]:checked + label, .hundreds-form__checkbox input[type=checkbox]:checked + label {color:$color-txt;}
.hundreds-form__checkbox input[type=radio]:checked + label:after, .hundreds-form__checkbox input[type=checkbox]:checked + label:after {background-color:$color;}

.form-control, form input[type=email], form input[type=text], form input[type=number], form input[type=password], form select {padding: 15px;border-radius:$border-radius;height: 52px;color:$color-txt;
    font-family: $main;font-weight:$font-weight-normal;font-size: 14px;background: $color-input;outline: 0;width: 100%;transition: all .5s cubic-bezier(.15,.9,.34,.95);-webkit-appearance: none;border: 1px solid $color-dark_grey;}
.custom-file{cursor: pointer;}
.custom-file-input{ height: 52px;cursor: pointer;}
.custom-file-label::after {position: absolute;top: -1px;right: -1px;bottom: 0;z-index: 3;display: block;height: 52px;line-height: 1.5;color: #ffffff;content: "Seleccionar arxiu";
    font-family: $main;background-color: #afb5b8;border-left: 1px solid $color-dark_grey;border-radius:5px;padding-top: 15px;border-top-left-radius: 0;border-bottom-left-radius: 0; font-size: 14px;}

form textarea{ height: auto!important; resize: none!important;}
select{height: 52px!important;}

form input[type=submit] {
    display:inline-block;
    position:relative;
    background:$color;
    font-size:14px;
    font-family:$main;
    font-weight:$font-weight-normal;
    cursor: pointer;
    border-radius:$border-radius;
    border:none;
    color: white;
    border:1px solid transparent;
    opacity:1;
    margin:0;
    outline: 0;
    padding: 15px 25px;
    width: 170px;
    -webkit-appearance: none;
    -moz-transition: all .2s cubic-bezier(.15,.9,.34,.95);  -o-transition: all .2s cubic-bezier(.15,.9,.34,.95); -webkit-transition: all .2s cubic-bezier(.15,.9,.34,.95); transition: all .2s cubic-bezier(.15,.9,.34,.95);
}
form input[type=submit]:hover {
    background:#00C97D;
}

::-webkit-input-placeholder {  opacity:0.8;}
:-moz-placeholder {   opacity:0.8;}
::-moz-placeholder {  opacity:0.8; }
:-ms-input-placeholder {   opacity:0.8;}





@media only screen and (max-width: 800px){
    form input[type=email], form input[type=text], form input[type=number], form input[type=password], form textarea { padding:10px; }
    select { padding: 12px;}
    form label { font-size: 14px;}
}
@media only screen and (max-width: 600px){

}
@media only screen and (max-width: 480px){
    form input[type=submit]{ width:100%;}
}
